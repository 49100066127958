<template>
  <div class="member_show">
    <div class="show_title">
      <img src="/img/account/icon_title_3.svg" alt="">
      <h4 class="txt-bold">訂單查詢</h4>
    </div>

    <div class="show_order">

      <div class="orderbox">
        <!-- 訂單item -->
        <div
          v-for="(item, key) in orderData"
          :key="`order${key}`"
          class="order_item">
            <!-- 訂單頭部 -->
            <div class="order_head">
              <p class="txt-grey">訂單編號</p>
              <div class="sb">
                <p class="order_num">{{item.order_no}}</p>
                <router-link
                :to="{ name: 'OrderDetail', params: { id: item.id } }"
                class="txt-grey p">查看訂單內容</router-link>
              </div>
            </div>
            <!-- 訂單內容 -->
            <div class="order_body">
              <!-- 一項內容用一個 order_line, 標題要置頂對齊使用.ct -->
              <div class="order_line">
                <p class="txt-grey">訂單日期</p>
                <div class="sb">
                  <p class="txt-bold">{{moment(item.created_at).format('YYYY-MM-DD')}}</p>
                  <router-link
                  v-if="item.status === '已完成'"
                  :to="{
                    name: 'OrderQuestionnaire',
                    params: {id: item.id},
                  }"
                  class="satisfaction_btn small">
                    填寫滿意度問卷&nbsp;
                    <img src="@/assets/img/account/icon_smile.svg" alt="">
                  </router-link>
                </div>
              </div>

              <!-- <div class="order_line ct">
                <p class="txt-grey">訂單憑證</p>
                <div class="sb">
                <div class="qrbox">
                  <img src="@/assets/img/account/howgroup.png" class="qr" alt="">
                  </div>
                </div>
              </div> -->

              <div class="order_line">
                <p class="txt-grey">金額</p>
                <div>
                  <vue-numeric
                  read-only currency="$" separator=","
                  :value="item.total_amount"></vue-numeric>
                </div>
              </div>

              <!-- 狀態 -->
              <div class="order_line ct">
                <p class="txt-grey">狀態</p>
                <div class="status_box">
                  <!-- 租借商品 -->
                  <div class="status_show_box">
                    <p>
                      <span style="color:#1FBCD6;">{{item.status}}</span>
                      ({{item.shipping}})
                    </p>
                    <!-- <div class="rent_status">
                      <p class="small">租借商品</p>
                      <p class="small">取件成功</p>
                    </div> -->
                    <!-- <small class="seemore txt-grey">
                      詳情&nbsp;
                      <img
                        src="img/icon/icon_down-g.svg"
                        style="width:7px;
                        margin-bottom:2px;"
                        alt="">
                    </small>  -->
                  </div>
                  <div class="fill_in col100 m768-off">
                    <div class="col100">
                      <!-- 狀態 -->
                      <div class="order_status">
                        <div class="status_box">
                          <!-- 租借商品 -->
                          <div class="status_line_box active">
                            <!-- 當前的狀態加上class="active", 過往狀態加上class="past" -->
                            <!-- 租借class="rent", 售賣class="buy" -->
                            <div
                              v-for="(cargo, key) in item.cargos"
                              :key="`cargo${key}`"
                              class="status_line_item rent"
                              :class="[
                                {active: (cargo.confirm === 1)?true:false},
                                {past: key < item.activeCargos.length - 1}
                              ]">
                                <span class="ball"></span>
                                <div>
                                  <p>{{cargo.name}}</p>
                                  <p
                                    v-if="item.activeCargos[key]"
                                    class="txt-grey small">
                                      {{
                                        moment(item.activeCargos[key].created_at)
                                          .format('YYYY-MM-DD')
                                      }}
                                  </p>
                                </div>
                            </div>
                            <!-- <div class="status_line_item rent past">
                              <span class="ball"></span>
                              <div>
                                <p>物流出貨</p>
                                <p class="txt-grey small">07/01 10:30</p>
                                <p class="txt-grey small">
                                  包裹碼: <span class="txt-blue">17263848248</span>
                                </p>
                                <a href="#" class="status_to_search small">前往查詢</a>
                              </div>
                            </div>
                            <div class="status_line_item rent active">
                              <span class="ball"></span>
                              <div>
                                <p>到達</p>
                                <p class="txt-grey small">06/30 17:30</p>
                              </div>
                            </div>
                            <div class="status_line_item rent">
                              <span class="ball"></span>
                              <div>
                                <p>取貨成功</p>
                              </div>
                            </div>
                            <div class="status_line_item rent">
                              <span class="ball"></span>
                              <div>
                              <p>還機成功</p>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="order_line m1023_ct">
                <p class="txt-grey">訂單內容</p>
                <div>
                  <div class="picbox">
                    <figure
                      class="cart_pic"
                      :style="
                        `background-image: url(${require('@/assets/img/shopping/product_1.png')}`
                      ">
                    </figure>
                  </div>
                  <p>
                    <span class="txt-bold">日本藍鑽石</span> 與其他2項商品
                  </p>
                </div>
              </div> -->
            </div>
        </div>
      </div>

      <!-- <Paginate/> -->

    </div>

  </div>
</template>

<script>
// import Paginate from '@/components/Paginate.vue';
import moment from 'moment';
import requestApi from '@/lib/http';

export default {
  name: 'MemberOrder',
  components: {
    // Paginate,
  },
  data() {
    return {
      orderData: [],
    };
  },
  methods: {
    moment,
    async getOrders() {
      this.orderData = [];
      const { message, data } = await requestApi('member.getOrders');

      if (data) {
        data.forEach((element) => {
          this.orderData.push(element);
        });
      } else {
        this.$customSWAL({
          icon: 'error',
          title: message,
        });
      }
    },
    ifFitSomething(arr, target) {
      let flag = false;
      arr.forEach((elem) => {
        if (target === elem.cargo_status_id) {
          flag = true;
        }
      });
      return flag;
    },
  },
  mounted() {
    const token = localStorage.getItem('ust');
    if (token) {
      this.getOrders();
    }
  },
};
</script>
